import { Injectable } from "@angular/core";
import { Observable, of, Subject, switchMap } from "rxjs";
import { finalize, map, startWith } from "rxjs/operators";
import {
    AddToCartDocument,
    AddToCartMutation,
    AddToWishlistDocument,
    AddToWishlistMutation,
    AdjustItemQuantityDocument,
    AdjustItemQuantityMutation,
    CartFragment,
    GetEligibleShippingMethodsDocument,
    RemoveItemFromCartDocument,
    RemoveItemFromCartMutation,
    SetShippingMethodDocument,
    TransitionToAddingItemsDocument
} from "../../../common/gql/graphql";

import { assertNever } from "../../../common/utils/assert-never";

import { DataService } from "../data/data.service";
import { StateService } from "../state.service";
import { NotificationService } from "../notification/notification.service";
import { GtagService } from "../gtag/gtag.service";

@Injectable({ providedIn: 'root' })
export class OrderService {
    processing$: Observable<boolean>;
    private mutationInFlight$ = new Subject<boolean>();

    constructor(
        private dataService: DataService,
        private stateService: StateService,
        private notificationService: NotificationService,
        private gtagService: GtagService,
    ) {
        this.processing$ = this.mutationInFlight$.asObservable().pipe(startWith(false));
    }

    addToCart(event: { productVariantId: string; quantity: number }): Observable<CartFragment | undefined> {
        const addToCart$ = this.dataService.mutate(AddToCartDocument, {
            variantId: event.productVariantId,
            qty: event.quantity,
        });
        const handler = ({ addItemToOrder }: AddToCartMutation): Observable<CartFragment | undefined> => {
            if (addItemToOrder.__typename === 'Order') {
                this.stateService.setState('activeOrderId', addItemToOrder.id);
                this.gtagService.addToCart(addItemToOrder, event.productVariantId);
                return of(addItemToOrder);
            }
            switch (addItemToOrder.__typename) {
                case 'OrderModificationError':
                    // If the Order is not in the AddingItems state, we transition to it
                    // and then attempt to add to cart again.
                    return this.dataService.mutate(TransitionToAddingItemsDocument).pipe(
                        switchMap(() => addToCart$),
                        switchMap(handler),
                    );
                case 'NegativeQuantityError':
                case 'OrderLimitError':
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    break;
                case 'InsufficientStockError':
                    this.stateService.setState('activeOrderId', addItemToOrder.order.id);
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    break;
                default:
                    assertNever(addItemToOrder);
            }
            return of(undefined);
        };
        this.mutationInFlight$.next(true);
        return addToCart$.pipe(
            switchMap(handler),
            switchMap((cart) => (cart ? this.ensureGiftCardShippingIsZero(cart) : of(null))),
            finalize(() => this.mutationInFlight$.next(false)),
        );
    }

    removeOrderLine(orderLineId: string): Observable<CartFragment | undefined> {
        return this.removeItem(orderLineId);
    }

    updateOrderLine({
        orderLineId,
        quantity,
    }: {
        orderLineId: string;
        quantity: number;
    }): Observable<CartFragment | undefined> {
        if (0 < quantity) {
            return this.adjustOrderLine({ orderLineId, quantity });
        } else {
            return this.removeItem(orderLineId);
        }
    }

    ensureGiftCardShippingIsZero(cart?: CartFragment): Observable<CartFragment> {
        if (!cart || cart.shippingLines.length === 0) {
            return of(cart);
        }
        const allLinesAreGiftCards = cart.lines.every((line) => line.giftCardInput != null);
        if (allLinesAreGiftCards && 0 < cart.shipping) {
            // Shipping should be zero for an order consisting solely of gift cards.
            return this.dataService
                .query(GetEligibleShippingMethodsDocument, {}, { fetchPolicy: 'network-only' })
                .pipe(
                    switchMap(({ eligibleShippingMethods }) => {
                        if (eligibleShippingMethods.length === 0) {
                            return of(cart);
                        }
                        return this.dataService.mutate(SetShippingMethodDocument, {
                            id: eligibleShippingMethods[0].id,
                        });
                    }),
                    map(() => cart),
                );
        } else {
            return of(cart);
        }
    }

    addToWishlist(data: {
        productVariantId: string;
        wishlistId?: string;
    }): Observable<AddToWishlistMutation['addToWishlist']> {
        return this.dataService
            .mutate(AddToWishlistDocument, {
                variantId: data.productVariantId,
                wishlistId: data.wishlistId,
            })
            .pipe(
                map(({ addToWishlist }) => {
                    this.stateService.setState('defaultWishlistId', addToWishlist.id);
                    return addToWishlist;
                }),
            );
    }

    private adjustOrderLine(event: {
        orderLineId: string;
        quantity: number;
    }): Observable<CartFragment | undefined> {
        this.mutationInFlight$.next(true);
        const adjustOrderLine$ = this.dataService.mutate(AdjustItemQuantityDocument, {
            id: event.orderLineId,
            qty: event.quantity,
        });

        const handler = ({
            adjustOrderLine,
        }: AdjustItemQuantityMutation): Observable<CartFragment | undefined> => {
            switch (adjustOrderLine.__typename) {
                case 'Order':
                    return of(adjustOrderLine);
                case 'OrderModificationError':
                    // If the Order is not in the AddingItems state, we transition to it
                    // and then attempt to add to cart again.
                    return this.dataService.mutate(TransitionToAddingItemsDocument).pipe(
                        switchMap(() => adjustOrderLine$),
                        switchMap(handler),
                    );
                case 'NegativeQuantityError':
                case 'OrderLimitError':
                case 'InsufficientStockError':
                    this.notificationService.error(adjustOrderLine.message).subscribe();
                    break;
                default:
                    assertNever(adjustOrderLine);
            }
            return of(undefined);
        };

        return adjustOrderLine$.pipe(
            switchMap(handler),
            switchMap((cart) => (cart ? this.ensureGiftCardShippingIsZero(cart) : of(null))),
            finalize(() => this.mutationInFlight$.next(false)),
        );
    }

    private removeItem(id: string) {
        this.mutationInFlight$.next(true);

        const removeItem$ = this.dataService.mutate(RemoveItemFromCartDocument, { id });

        const handler = ({
            removeOrderLine,
        }: RemoveItemFromCartMutation): Observable<CartFragment | undefined> => {
            if (removeOrderLine.__typename === 'Order') {
                return of(removeOrderLine);
            }
            switch (removeOrderLine.__typename) {
                case 'OrderModificationError':
                    // If the Order is not in the AddingItems state, we transition to it
                    // and then attempt to add to cart again.
                    return this.dataService.mutate(TransitionToAddingItemsDocument).pipe(
                        switchMap(() => removeItem$),
                        switchMap(handler),
                    );
                default:
                    assertNever(removeOrderLine);
            }
            return of(undefined);
        };
        this.mutationInFlight$.next(true);
        return removeItem$.pipe(
            switchMap(handler),
            switchMap((cart) => (cart ? this.ensureGiftCardShippingIsZero(cart) : of(null))),
            finalize(() => this.mutationInFlight$.next(false)),
        );
    }
}
